@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');

@layer base {
	body {
		@apply bg-gray-50;
		font-family: 'Poppins', sans-serif;
		@apply max-w-6xl mx-auto;
	}
}

@layer components {
	.btn {
		@apply hover:scale-125 cursor-pointer transition-all duration-150 ease-out;
	}
	.form {
		@apply border mb-1 px-1 py-1 rounded-sm bg-white outline-none;
	}
}
